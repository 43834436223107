import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ApiServiceService } from 'src/app/api/api-service.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { EsqueciMinhaSenhaPageModule } from './esqueci-minha-senha.module';
@Component({
  selector: 'app-esqueci-minha-senha',
  templateUrl: './esqueci-minha-senha.page.html',
  styleUrls: ['./esqueci-minha-senha.page.scss'],
})
export class EsqueciMinhaSenhaPage implements OnInit {

  constructor(
    public modalController: ModalController,
    public apiService: ApiServiceService,
    private fb: FormBuilder,

  ) { }
  restorePassword: FormGroup;

  async ngOnInit() {
    this.restorePassword = this.fb.group({
      cpfresetPassword: ['',Validators.required]
    });
    
  }

  get cpfresetPassword() {
    return this.restorePassword.get('cpfresetPassword');
  }

  dismiss() {
    // using the injected ModalController this page
    // can "dismiss" itself and optionally pass back data
    this.modalController.dismiss({
      'dismissed': true
    });
  }

}
