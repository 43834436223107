import { Injectable } from '@angular/core';
import { LocalNotifications,NotificationChannel } from '@capacitor/local-notifications';

@Injectable({
  providedIn: 'root'
})
export class LocalNotificationService {

  constructor(){
  const notificacaoPadrao: NotificationChannel = { id: 'notificaopadrao', description: "Notificação Padrão", name: 'notificaopadrao', importance: 5, sound: 'notification.wav', vibration: true };
  const retornoRefeicao: NotificationChannel = { id: 'retornorefeicao', description: "Retorno da Refeição", name: 'retornorefeicao', importance: 5, sound: 'retorno_refeicao.wav', vibration: true };
  const errorPonto: NotificationChannel = { id: 'erroponto', description: "Ponto com erro", name: 'erroponto', importance: 5, sound: 'notification_error.wav', vibration: true };
    
  try {
      LocalNotifications.deleteChannel(notificacaoPadrao);
      LocalNotifications.deleteChannel(retornoRefeicao);
      LocalNotifications.deleteChannel(errorPonto);

      LocalNotifications.createChannel(notificacaoPadrao);
      LocalNotifications.createChannel(retornoRefeicao);
      LocalNotifications.createChannel(errorPonto);
    } catch (error) {
      console.log("error",error);
    }

  }

  async showLocalNotification( title : string, text : string, addSeconds: number = 1,groupNotification = "notificaopadrao"){
    const randomId = Math.floor(Math.random() * 10000) + 1;


    if(addSeconds == 1){
      const shceduled = {
        notifications:[
        {
            title : title,
            body : text,
            largeBody: text,
            id : randomId,
            smallIcon: "ic_stat_tc",
            iconColor: "#0000FF",
            channel: groupNotification,
            channelId: groupNotification
        }
        ]

        }
        try {  
          LocalNotifications.schedule(shceduled)
        } catch (error) {
        }
      
    } else {
      console.log('Adicionado Os Segundos'+addSeconds);
      LocalNotifications.schedule({
        notifications: [
          {
            title: title,
            body: text,
            largeBody: text,
            id: randomId,
            smallIcon: "ic_stat_tc",
            iconColor: "#0000FF",            
            channelId: groupNotification,
            schedule: {
              at: new Date(Date.now() + (1000* addSeconds)), // in a minute

            }

          }
        ]
      });
    }



  }
}

